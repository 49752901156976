import Button from '../../../../components/button/button';
import MockImage from '../../../../assets/img/mock.png';

import styles from './hero.module.scss';

const Hero = () => {
    return (
        <section className={styles.hero}>
            <div className={styles.content}>
                <h1>La red social de la gastronomía</h1>
                <span>
                    Cappacho es tu espacio para compartir todo sobre
                    gastronomía. Desde tus comidas diarias y recetas favoritas
                    hasta los restaurantes que visitas, aquí puedes conectar con
                    otros amantes de la buena comida.
                </span>
                <div className={styles.buttonContainer}>
                    <Button>Únete a la comunidad</Button>
                </div>
            </div>
            <div className={styles.heroImageContainer}>
                <img
                    className={styles.heroImage}
                    src={MockImage}
                    alt="mock"
                    width="320"
                />
            </div>
        </section>
    );
};

export default Hero;
