import { useState } from 'react';

import Toast from '../../components/toast/toast';
import { sendContactForm } from '../../services/contact-form';

import styles from './home.module.scss';
import Hero from './containers/hero/hero';
import Contact from './containers/contact/contact';
import Footer from './containers/footer/footer';

const HomePage = () => {
    const [loading, setLoading] = useState(false);
    const [toastShown, setToastShown] = useState(false);

    const showToast = () => {
        setToastShown(true);
        setTimeout(() => {
            setToastShown(false);
        }, 4000);
    };

    const onSubmit = async (form: any) => {
        setLoading(true);
        const { success } = await sendContactForm({
            name: form.name.trim(),
            email: form.email.toLowerCase().trim(),
        });
        setLoading(false);
        success && showToast();
    };

    return (
        <main id={styles.home}>
            <Hero />
            <Contact loading={loading} onSubmit={onSubmit} />
            <Footer />
            <Toast
                message="¡Genial! Te has unido a la lista de espera"
                shown={toastShown}
            />
        </main>
    );
};

export default HomePage;
