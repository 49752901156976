import Logo from '../../icons/logo';
import Button from '../button/button';
import { useMediaQuery } from '../../hooks/media-query';

import styles from './main-nav.module.scss';

const MainNav = () => {
    const matches = useMediaQuery('(max-width: 720px)');

    return (
        <nav className={styles.menu}>
            <Logo color="#f60d0d" scale={matches ? 0.85 : 1.25} />
            <Button>Únete a la comunidad</Button>
        </nav>
    );
};

export default MainNav;
