// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: 'AIzaSyD95SK6cS44UOgF8eHVFuueuipGlSal1lo',
    authDomain: 'cappacho-prod.firebaseapp.com',
    projectId: 'cappacho-prod',
    storageBucket: 'cappacho-prod.firebasestorage.app',
    messagingSenderId: '636317615358',
    appId: '1:636317615358:web:452118922554b50b0851af',
    measurementId: 'G-VZE0XGH3DT',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const initFirebaseAnalytics = () => getAnalytics(app);

export { initFirebaseAnalytics };
